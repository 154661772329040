import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 4×4\\@75% with :03 pause at bottom`}</p>
    <p>{`DB Stiff Leg Deadlifts 4×4`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 AMRAP of:`}</p>
    <p>{`20/15 Calorie Assault Bike`}</p>
    <p>{`20 Wall Balls (20/14)`}</p>
    <p>{`20 Situps`}</p>
    <p><strong parentName="p">{`*`}{`Memorial Day Murph is this coming Monday! Sign up for the 9:00am or
10:30am class using the sign up sheet by the front board.`}</strong>{` `}<em parentName="p">{`(All other
classes will be cancelled for Memorial Day)`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      